import profileService from '../../services/profile'
import {handleReqWithPaginatedRes} from '@/helpers'

export default {
	namespaced: true,
    state: {
    	profiles: [],
		top_profiles: [],
		new_profiles: [],
		visitor_profiles: [],
		visitor_profiles_meta: null,
		favorite_profiles: [],
		favorite_profiles_meta: null,
		other_favorite_profiles: [],
		other_favorite_profiles_meta: null,
		search_result_profiles: [],
		search_result_profiles_meta: null,
		recent_activity: [],
		dashboard_loading: false,
    },
    getters: {
		profiles(state) {
			return state.profiles
		},
		topProfiles(state) {
			return state.top_profiles
		},
		newProfiles(state) {
			return state.new_profiles
		},
		visitorProfiles(state) {
			return state.visitor_profiles
		},
		favoriteProfiles(state) {
			return state.favorite_profiles
		},
		otherFavoriteProfiles(state) {
			return state.other_favorite_profiles
		},
		searchResultProfiles(state) {
			return state.search_result_profiles
		},
		visitorProfilesMeta(state) {
			return state.visitor_profiles_meta
		},
		favoriteProfilesMeta(state) {
			return state.favorite_profiles_meta
		},
		otherFavoriteProfilesMeta(state) {
			return state.other_favorite_profiles_meta
		},
		searchResultProfilesMeta(state) {
			return state.search_result_profiles_meta
		},
		recentActivity(state) {
			return state.recent_activity
		},
		getDashboardLoading(state) {
			return state.dashboard_loading
		}
    },
    mutations: {
		setProfiles(state, payload) {
			state.profiles = payload
		},
		setTopProfiles(state, payload) {
			state.top_profiles = payload
		},
		setNewProfiles(state, payload) {
			state.new_profiles = payload
		},
		setVisitorProfiles(state, payload) {
			state.visitor_profiles = payload
		},
		setFavoriteProfiles(state, payload) {
			state.favorite_profiles = payload
		},
		setOtherFavoriteProfiles(state, payload) {
			state.other_favorite_profiles = payload
		},
		setSearchResultProfiles(state, payload) {
			state.search_result_profiles = payload
		},
		addBannerInSearchResults(state, payload) {
			let addedBanner = (state.search_result_profiles || []).find(obj => obj.adbanner) || null
			if (addedBanner === null) {
				state.search_result_profiles.push({adbanner: true})
			}
		},
		setVisitorProfilesMeta(state, payload) {
			state.visitor_profiles_meta = payload
		},
		setFavoriteProfilesMeta(state, payload) {
			state.favorite_profiles_meta = payload
		},
		setOtherFavoriteProfilesMeta(state, payload) {
			state.other_favorite_profiles_meta = payload
		},
		setSearchResultProfilesMeta(state, payload) {
			state.search_result_profiles_meta = payload
		},
		setRecentActivity(state, payload) {
			state.recent_activity = payload
		},
		resetProfiles(state, payload) {
			state.profiles = []
			state.top_profiles = []
			state.new_profiles = []
		},
		setDashboardLoading(state, payload) {
			state.dashboard_loading = payload
		}
	},
	actions: {
		populateProfiles(context, payload) {
			context.commit('resetProfiles')
			if (context.rootGetters['auth/isAuthenticated']) {
                console.log('populateProfiles:auth');
				profileService.getPopularProfiles()
					.then(res => {
						context.commit('setProfiles', res.data.data)
					}).catch(error => console.log(error))
			} else {
                console.log('populateProfiles:no-auth');
				profileService.getOnlineUsers()
					.then(res => {
						context.commit('setProfiles', res.data)
					}).catch(error => console.log(error))
			}
            console.log('populateProfiles:getDashboard:call');
			context.dispatch('getDashboard', payload);
		},
		getDashboard(context, payload) {
			if (context.state.dashboard_loading) {
                console.log('getDashboard:dashboard_loading');
				return;
			}
            const isAuth = context.rootGetters['auth/isAuthenticated'];
			let getProfiles = null
			if (isAuth) {
				if (payload?.updates) {
                    console.log('getDashboard:auth:update');
					getProfiles = profileService.getDashboardUpdates()
				} else {
                    console.log('getDashboard:auth:no-update');
                    getProfiles = profileService.getDashboard()
				}
			} else {
                console.log('getDashboard:no-auth');
				getProfiles = profileService.getGuestDashboard()
			}
			context.commit('setDashboardLoading', true);
			getProfiles
				.then(res => {
                    if (!isAuth && context.rootGetters['auth/isAuthenticated']) {
                        console.log('getDashboard:re-call', getProfiles);
                        context.commit('setDashboardLoading', false);
                        context.dispatch('getDashboard', payload);
                        return;
                    }
					if ('popular_profiles' in res.data) {
						context.commit('setTopProfiles', res.data.popular_profiles)
					}
					if ('new_profiles' in res.data) {
						context.commit('setNewProfiles', res.data.new_profiles)
					}
					if ('updates' in res.data) {
						context.commit('setRecentActivity', res.data.updates)
					}
					if ('my_profile' in res.data) {
						context.dispatch('auth/updateProfile', res.data.my_profile, {root: true})
					}
					context.commit('setDashboardLoading', false);
				}).catch(() => {
				  context.commit('setDashboardLoading', false);
			  })
		},
		getProfile(context, payload) {
			return profileService.getProfile(payload)
		},
		getProfileImages(context) {
			return profileService.getImages()
		},
		uploadProfileImage(context, payload) {
			return profileService.uploadProfileImage(payload)
		},
		uploadImage(context, payload) {
			return profileService.uploadImage(payload)
		},
		uploadPrivateImage(context, payload) {
			return profileService.uploadPrivateImage(payload)
		},
		updateUploadedImage(context, payload) {
			return profileService.updateUploadedImage(payload)
		},
		deleteImage(context, id) {
			return profileService.deleteImage(id)
		},
		getVisitors(context, {params, success_cb}) {
			profileService.getVisitors({params})
				.then(response => {
					handleReqWithPaginatedRes(context, {
						response,
						list_mutation: 'setVisitorProfiles',
						meta_mutation: 'setVisitorProfilesMeta',
						success_cb
					})
				}).catch(error => console.log(error))
		},
		getFavorites(context, {params, success_cb}) {
			profileService.getFavorites({params})
				.then(response => {
					handleReqWithPaginatedRes(context, {
						response,
						list_mutation: 'setFavoriteProfiles',
						meta_mutation: 'setFavoriteProfilesMeta',
						success_cb
					})
				}).catch(error => console.log(error))
		},
		getOtherFavorites(context, {params, success_cb}) {
			profileService.getOtherFavorites({params})
				.then(response => {
					handleReqWithPaginatedRes(context, {
						response,
						list_mutation: 'setOtherFavoriteProfiles',
						meta_mutation: 'setOtherFavoriteProfilesMeta',
						success_cb
					})
				}).catch(error => console.log(error))
		},
		addFavorite(context, {nick_name}) {
			return profileService.addFavorite(nick_name)
		},
		removeFavorite(context, {nick_name}) {
			return profileService.removeFavorite(nick_name)
		},
		getSearchResults(context, {params, success_cb}) {
			context.dispatch('search/getSearchResults', params, {root: true})
				.then(response => {
					handleReqWithPaginatedRes(context, {
						response,
						list_mutation: 'setSearchResultProfiles',
						meta_mutation: 'setSearchResultProfilesMeta',
						success_cb
					})
    			}).catch(error => console.log(error))
    	},
		getWebCamUrl(context) {
			return profileService.getWebCamUrl()
		}
    }
}
